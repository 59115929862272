export enum MenuItemStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  HIDE = 'HIDE',
}

export enum MenuItemTypes {
  OVERVIEW = 'OVERVIEW',
  SIMULATE = 'SIMULATE',
  BILLING = 'BILLING',
  CERTIFICATE = 'CERTIFICATE',
  OPTIMIZE = 'OPTIMIZE',
  ABOUT = 'ABOUT',
  SETTINGS = 'SETTINGS',
  CAPEXOPEX = 'CAPEXOPEX',
  SIZINGANDCOSTING = 'SIZINGANDCOSTING',
}

export type IMenuItem = {
  type: MenuItemTypes;
  text: string;
  status: MenuItemStatus;
};
